import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css'

const Footer = () => {
  const navigate = useNavigate();

  const openModal = () => {
    navigate('/notice');
  };

  return (
    <footer>
      <p>
        <button id='copyrightButton' onClick={openModal}>© {new Date().getFullYear()} TCGPriceCheck.com. All rights reserved.</button>
      </p>
    </footer>
  );
};

export default Footer;
