import React, { useState, useEffect } from 'react';

const SaleImageModal = ({ signature, imgKey, screenshot }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    setImageUrl(`http://localhost/${imgKey}.jpg`);
  }, [imgKey]);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <button
        onClick={screenshot ? openModal : null}
        disabled={!screenshot}
        style={{
          color: screenshot ? 'inherit' : 'black',
          backgroundColor: screenshot ? 'inherit' : 'white',
          borderColor: screenshot ? 'inherit' : 'white',
          cursor: screenshot ? 'pointer' : 'not-allowed',
        }}
      >
        {signature}
      </button>

      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div style={{ position: 'relative', width: '50%', height: '50%' }}>
            <img
              src={imageUrl}
              alt="Modal"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: 'red',
                color: 'white',
              }}
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaleImageModal;
