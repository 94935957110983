import React from 'react';
import './HomePage.css'
//import CardSearchForm from './CardSearchForm';
//import SignatureExplanation from './SignatureExplanation';

const HomePage = () => {
    return (
        <div className="page">
            <header className="header">
                <h1>TCG Price Check</h1>
            </header>
            <div className="content-home-page">
                <p>Come back later - Site Under Construction</p>
            </div>
        </div>
    );
}
 
export default HomePage;