import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import SimpleResultPage from './SimpleResultPage';
import Footer from './Footer';
import NoticeModal from './NoticeModal';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/SimpleResultPage" element={<SimpleResultPage />} />
        <Route path="/notice" element={<NoticeModal />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
