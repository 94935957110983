import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import SaleImageModal from './SaleImageModal';
//import LineGraph from './LineGraph';
import './SimpleResultPage.css';
//import CardSearchForm from './CardSearchForm';

const SimpleResultPage = () => {
    const location = useLocation();
    const pokemonName = location.state.pokemonName;
    const expansionValue = location.state.expansionValue;
    const conditionValue = location.state.conditionValue;
    //const [estimatedValue, setEstimatedValue] = useState('');
    const [saleData, setSaleData] = useState(null);
    const [flagCount, setFlagCount] = useState(0);
    const [header, setHeaderString] = useState(null);
    //const navigate = useNavigate();

    const createQueryString = (pokemonName, expansionValue, conditionValue) => {
      const baseEndpoint = 'https://localhost:7172/SaleHistory';
      //const baseEndpoint = 'https://192.168.0.23:7172/SaleHistory';
      let queryString;
    
      if (conditionValue >= 7 && conditionValue <= 10) {
        queryString = `${baseEndpoint}?PokemonName=${pokemonName}&SetAbbreviation=${expansionValue}&Grade=${conditionValue}`;
      } else {
        queryString = `${baseEndpoint}?PokemonName=${pokemonName}&SetAbbreviation=${expansionValue}&Condition=${conditionValue}`;
      }
    
      return queryString;
    };

    const createHeaderString = (pokemonName, expansionValue, conditionValue) => {
      let headerString = pokemonName || "Pokemon";
    
      if (expansionValue) {
        headerString += ` | ${expansionValue}`;
      }
    
      if (conditionValue) {
        if (conditionValue >= 7 && conditionValue <= 10)
        {
          headerString += ` | Graded ${conditionValue}`;
        }
        else
        {
          headerString += ` | Ungraded ${conditionValue}`;
        }
      }
    
      return headerString;
    };

    const calculateEstimatedValue = useCallback((saleHistoryItems) => { 
      let sum = 0; 
      for (let i = 0; i < saleHistoryItems.length; i++) { 
        sum += saleHistoryItems[i].salePrice; 
      } 
      return formatToUSD(sum / saleHistoryItems.length);
     }, []);
    //const handleHomeButtonClick = () =>
    //{
    //  navigate('/');
    //}

    const formatToUSD = (numberToFormat) =>
    {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    
      return formatter.format(numberToFormat);
    }
    
    useEffect(() => {
      const queryString = createQueryString(pokemonName, expansionValue, conditionValue);
      setHeaderString(createHeaderString(pokemonName, expansionValue, conditionValue));
      
    
      fetch(queryString)
        .then((response) => response.json())
        .then((data) => {
          setSaleData(data.saleHistoryItems);
          /*
          if (pokemonName && expansionValue && conditionValue && data.saleHistoryItems.length > 0)
          {
            setEstimatedValue(calculateEstimatedValue(data.saleHistoryItems));
          }
          else
          {
            setEstimatedValue("Not Applicable");
          }
            */
        })
        .catch((error) => console.error("Error", error));
    }, [calculateEstimatedValue, conditionValue, expansionValue, pokemonName]);

    const handleFlagButtonPress = async (key) => {
      try {
        if (flagCount < 3) {
          const commandString = `https://localhost:7172/SaleAccuracy?Key=${key}`;
    
          const response = await fetch(commandString, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (response.status === 200) {
            alert("Sale successfully flagged.");
          } else {
            alert("Error flagging sale.");
          }
    
          setFlagCount(flagCount + 1);
        } else {
          alert("You have flagged too many times.");
        }
      } catch (error) {
        alert("Error flagging sale.");
      }
    };
    
//{estimatedValue}

    return (
      <div className="page">
        <div>
          <header className="header">
            <h1>{header}</h1>
            <p>Estimated Value: NA</p>
          </header>
          {saleData ? (
            <div className="content-simple-result-page">
              <div class='hide-on-small' id='card-search-form'>
                
              </div>
              <div className='table-container'>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Sale Price</th>
                      <th>Sale Date</th>
                      <th class='hide-on-small'>Condition</th>
                      <th class='hide-on-small'>Grader</th>
                      <th>Grade</th>
                      <th class='hide-on-small'>Expansion</th>
                      <th class='hide-on-small'>Signature</th>
                      <th class='hide-on-small'>Flag</th>
                    </tr>
                  </thead>
                  <tbody>
                    {saleData.map((item, index) => {
                      let backgroundColor = item.accuracy < 0 ? '#ff9c9c' : null;
                      let priceInUSD = '$10';//= formatToUSD(item.salePrice);
                      let saleDate = item.saleDate.split(' ')[0];

                      return (
                        <tr key={index} id={item.key} accuracy={item.accuracy} style={{ backgroundColor: backgroundColor }}>
                          <td>{item.name}</td>
                          <td>{priceInUSD}</td>
                          <td>{saleDate}</td>
                          <td class='hide-on-small'>{item.condition === 'UKN' ? 'Unknown' : item.condition}</td>
                          <td class='hide-on-small'>{item.grader}</td>
                          <td>{item.grade === 0 ? 'Ungraded' : item.grade}</td>
                          <td class='hide-on-small'>{item.setAbbreviation}</td>
                          {/* <td>{item.signature}</td> */}
                          <td class='hide-on-small'>
                            <SaleImageModal signature={item.signature} imgKey={item.key} screenshot={item.screenshot}/>
                          </td>
                          <td class='hide-on-small'>
                            <button onClick={() => handleFlagButtonPress(item.key)}>🚩</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            'Loading...'
          )}
        </div>
      </div>
    );
}
 
export default SimpleResultPage