import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoticeModal = () => {
  const navigate = useNavigate();

  const closeModal = () => {
    navigate('/');
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.75)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div style={{ position: 'relative', width: '35%', height: '50%', backgroundColor: 'white', padding: '20px', overflowY: 'auto' }}>

        <h2>Terms and Conditions</h2>
        <h3>1. Introduction</h3>
        <p>
          Welcome to TCGPriceCheck.com! By accessing our website, you agree to be bound by these
          terms and conditions.
        </p>
        <h3>2. Use of Website</h3>
        <p>
          You are granted a non-exclusive, non-transferable, revocable license to access and use
          our website for personal, non-commercial purposes only.
        </p>
        <h3>3. Content</h3>
        <p>
          We are not associated with GameFreak, The Pokemon Company, Nintendo, or any other entities mentioned on this site.
          Additionally, we do not claim ownership of their respective intellectual properties or assets.

          This website and its content, including any use of copyrighted materials, are intended to be used soley for informational purposes,
          which is granted by the Pokemon company here: https://press.pokemon.com/en/Assets-Use-Terms .
        </p>
        <h3>4. User Conduct</h3>
        <p>
          You agree to use our website in a lawful manner and not to engage in any activities that
          are illegal or prohibited by these Terms.
        </p>
        <h3>5. Limitation of Liability</h3>
        <p>
          In no event shall TCGPriceCheck.com be liable for any damages, including but not limited to,
          direct, indirect, incidental, punitive, and consequential damages arising out of the use of
          or inability to use our website.

          We do not guarantee the accuracy of the sales data available on our site. You understand the
          risk you take by making decisions using any information we make available.
        </p>
        <h3>6. Changes to Terms</h3>
        <p>
          We reserve the right to modify these terms at any time. Your continued use of our website following
          any such changes constitutes your acceptance of the new terms.
        </p>
        <h3>7. Governing Law</h3>
        <p>
          These terms shall be governed by and construed in accordance with the laws of USA/Minnesota.
        </p>
        <h3>8. Contact Us</h3>
        <p>
          If you have any questions about these terms or anything else, please contact us at
          crimsonplanetsoftware@gmail.com.
        </p>

        <button
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'red',
            color: 'white',
          }}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default NoticeModal;
